// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-announcing-telepresence-2-js": () => import("./../../../src/pages/announcing-telepresence-2.js" /* webpackChunkName: "component---src-pages-announcing-telepresence-2-js" */),
  "component---src-pages-case-studies-bitnami-js": () => import("./../../../src/pages/case-studies/bitnami.js" /* webpackChunkName: "component---src-pages-case-studies-bitnami-js" */),
  "component---src-pages-case-studies-engel-volkers-js": () => import("./../../../src/pages/case-studies/engel-volkers.js" /* webpackChunkName: "component---src-pages-case-studies-engel-volkers-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-iris-tv-js": () => import("./../../../src/pages/case-studies/iris-tv.js" /* webpackChunkName: "component---src-pages-case-studies-iris-tv-js" */),
  "component---src-pages-case-studies-sight-machine-js": () => import("./../../../src/pages/case-studies/sight-machine.js" /* webpackChunkName: "component---src-pages-case-studies-sight-machine-js" */),
  "component---src-pages-case-studies-verloop-js": () => import("./../../../src/pages/case-studies/verloop.js" /* webpackChunkName: "component---src-pages-case-studies-verloop-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-related-projects-js": () => import("./../../../src/pages/related-projects.js" /* webpackChunkName: "component---src-pages-related-projects-js" */),
  "component---src-templates-doc-page-js": () => import("./../../../src/templates/doc-page.js" /* webpackChunkName: "component---src-templates-doc-page-js" */)
}

